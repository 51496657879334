import React, { useState,useContext } from "react";
import AiTest from "../Component/AiTest";
import Login from "../utils/Login";
import { UserContext } from "../App";
import CreatePetProfile from "../Component/BookingComponent/CreatePetProfile"

function MainPage(){
    const { User } = useContext(UserContext);
    const uid = User && User.user.uid;
    const [renderLogin, setRenderLogin] = useState(false);
    const [showAiTest,setShowAiTest]=useState(false);
    const [createPetProfile,setCreatePetProfile]=useState(false);

    const handleAisstant =async()=>{
      if(User){
        setShowAiTest(true)
      }else{
        setRenderLogin(true)
      }
    }
    return(
 
        <div className="mx-auto  sm:w-full 2xl:w-5/6  bg-slate-100  justify-center  gap-8  p-2 font-mono relative">
            <div className=" md:flex items-center p-4 ">
               <div className="  p-4 w-full md:w-1/2">
                <h1 className="font-bold text-3xl md:text-5xl">Are You Worried About Your Pet?</h1>
                <p className="font-medium text-md break-words ">Need A Meal Plan Or What Fun Activities You Can Do With 
                    Your Pet,Talk To Your Personal Pet Assistant About Anything....</p>
                    <button onClick={handleAisstant} className="bg-slate-900 text-white rounded-md text-center px-4 py-2 mt-4 w-[90%] font-bold  text-2xl items-center">Ask VetiQ</button>
               </div>
               <div className=" md:w-1/2 ">
                <img src="../Images/dogandhuman.svg" className="max-w-[80%]"></img>
               </div>
            </div>
            {/* the second div */}
            <div className=" mx-auto  md:flex justify-center rounded-lg  font-mono ">
            <div className=" flex flex-col bg-slate-50    rounded-lg  font-mono m-2 text-center p-4 items-center">
                <img src="../Images/calender.svg" className="w-32 h-24"></img>
              <h1 className="font-bold text-xl">Perfect Your Pet's Meal Plan</h1>
              <p className="font-medium text-sm">Find the best meal plans and recipes for your pet. Get  tips on balanced diets, portion control, and tasty meals to keep your pet healthy and happy.</p>
             </div>
             <div className=" flex flex-col bg-slate-50    rounded-lg  font-mono m-2 text-center p-4 items-center">
                <img src="../Images/pettips.svg" className="w-36 h-24"></img>
              <h1 className="font-bold text-xl">Essential Petcare Tips</h1>
              <p className="font-medium text-sm">Find top pet care tips for your pet's health and well-being. Get practical advice on grooming, training, and preventative care to ensure your pet thrives</p>
             </div>
             <div className=" flex flex-col bg-slate-50    rounded-lg  font-mono m-2 text-center p-4 items-center">
                <img src="../Images/petfood.svg" className="w-32 h-24"></img>
              <h1 className="font-bold text-xl">Healthy Pet Recipes</h1>
              <p className="font-medium text-sm">Discover easy and nutritious pet recipes for homemade meals. Treat your furry friend to delicious dishes that promote overall health and happiness.</p>
             </div>
            </div>
             <div className="w-[90%] md:w-6/12  top-0 right-0 absolute"> 
             {
                showAiTest ? <AiTest  uid={uid}  createPetProfile={createPetProfile} setShowAiTest={setShowAiTest} setCreatePetProfile={setCreatePetProfile}/>:null
             }
             </div>
             {/* <div className=" top-0 right-0 absolute"> 
             {createPetProfile ?<CreatePetProfile uid={uid} setCreatePetProfile={setCreatePetProfile}/>:null  }
             </div> */}
             {renderLogin &&  <Login  setRenderLogin={setRenderLogin}/>}
        </div>
    )
}
export default MainPage;